import './App.css';
import DealerReportingSearch from './components/DealerReportingSearch';
import {useAuth} from "react-oidc-context";

function App() {
    const auth = useAuth();

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div className="centered app-branding">Signing you in...</div>;
        case "signoutRedirect":
            return <div className="centered app-branding">Signing you out...</div>;
    }

    if (auth.isLoading) {
        return <div className="centered app-branding">Loading...</div>;
    }

    if (auth.error) {
        return <div className="centered app-branding">An error has occurred! Please contact the Development
            Team<br/><br/>(Error message: {auth.error.message})</div>;
    }

    if (auth.isAuthenticated) {
        return (
            <div className="App">
                <DealerReportingSearch/>
            </div>
        );
    }

    return auth.signinRedirect();
}

export default App;
